//
// progress.scss
//

// Custom height

// Progress height small
.progress-sm {
    height: 5px;
}

// Progress height medium
.progress-md {
    height: 8px;
}

// Progress height large
.progress-lg {
    height: 12px;
}

// Progress height extra large
.progress-xl {
    height: 15px;
}

// Progress height dubble extra large
.progress-xxl {
    height: 25px;
}

/* Progressbar Vertical */
.progress-vertical {
    min-height: 250px;
    height: 250px;
    width: 10px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;

    .progress-bar {
        width: 100%;
    }

    &.progress-xl {
        width: 15px;
    }

    &.progress-lg {
        width: 12px;
    }

    &.progress-md {
        width: 8px;
    }

    &.progress-sm {
        width: 5px;
    }
}

.progress-vertical-bottom {
    min-height: 250px;
    height: 250px;
    position: relative;
    width: 10px;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;

    .progress-bar {
        width: 100%;
        bottom: 0;
        position: absolute;
    }

    &.progress-xl {
        width: 15px;
    }

    &.progress-lg {
        width: 12px;
    }

    &.progress-md {
        width: 8px;
    }

    &.progress-sm {
        width: 5px;
    }
}

// Progress with percentage

.progress-w-percent {
    min-height: 20px;
    margin-bottom: 20px;

    .progress {
        width: 80%;
        float: left;
        margin-top: 8px;
    }

    .progress-value {
        width: 20%;
        float: right;
        text-align: right;
        line-height: 20px;
    }
}

/* -----------------------
Timeline Area  
--------------------------*/
.timeline-line {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: hsla(0, 0%, 76.9%, 0.22);

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
    }

    &::before {
        height: 100%;
        bottom: 0;
        background: linear-gradient(180deg, var(#0f0f11), rgba(19, 19, 19, 0));
    }

    &::after {
        height: 200px;
        top: 0;
        background: linear-gradient(180deg, #0f0f11, rgba(19, 19, 19, 0));
    }
}

.timeline-dot {
    position: absolute;
    top: 50%;
    left: 0;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        border-radius: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    &::before {
        z-index: 1;
        width: 2.1rem;
        height: 2.1rem;
        border-radius: 50%;
        background: linear-gradient(180deg, #059dff, #059dff);
        box-shadow: 0 0 15px rgba(82, 56, 255, 0.5);
    }

    &::after {
        border: 5px solid #0f0f11;
        background: radial-gradient(50% 50% at 50% 50%, #1e1e1e 48.96%, #1e1e1e 100%);
        z-index: 0;
        width: 31px;
        height: 31px;
    }

    .time-line-circle {
        position: absolute;
        top: 50%;
        border-radius: 50%;
        left: 50%;
        width: 100px;
        height: 100px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(242, 201, 76, 0) 40.62%, rgba(82, 56, 255, 0.5) 100%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        will-change: transform;
        -webkit-animation: radialanimation 2.2s cubic-bezier(0.26, 1.04, 0.54, 1) infinite;
        animation: radialanimation 2.2s cubic-bezier(0.26, 1.04, 0.54, 1) infinite;
    }
}

/*------------------------------
    Single Timeline Styles  
-------------------------------*/
.single-timeline {
    position: relative;
    padding-left: 100px;

    @media #{'only screen and (min-width: 768px) and (max-width: 991px)'} {
        padding-left: 50px;
    }

    @media #{'only screen and (max-width: 767px)'} {
        padding-left: 30px;
    }

    .single-content {
        background: linear-gradient(180deg, #1e1e1e 20%, rgba(19, 19, 19, 0.4));
        -webkit-backdrop-filter: blur(4px) opacity(0.95);
        backdrop-filter: blur(4px) opacity(0.95);
        border-radius: 16px;

        .inner {
            padding: 50px;
            @media #{'only screen and (min-width: 768px) and (max-width: 991px)'} {
                padding: 30px;
            }
            @media #{'only screen and (max-width: 767px)'} {
                padding: 20px;
            }
            .content {
                .date-of-timeline {
                    color: #acacac;
                    display: block;
                    margin-bottom: 30px;
                    letter-spacing: 0.5px;
                    font-style: italic;
                    font-size: 14px;

                    @media #{'only screen and (min-width: 768px) and (max-width: 991px)'} {
                        margin-bottom: 14px;
                    }
                    @media #{'only screen and (max-width: 767px)'} {
                        margin-bottom: 14px;
                    }
                }
                .title {
                    margin-bottom: 0;
                    @media #{'only screen and (min-width: 992px) and (max-width: 1199px)'} {
                        font-size: 32px;
                    }
                }
                .description {
                    margin-top: 30px;
                    @media #{'only screen and (min-width: 768px) and (max-width: 991px)'} {
                        margin-top: 20px;
                    }
                    @media #{'only screen and (max-width: 767px)'} {
                        margin-top: 15px;
                    }
                }
            }
        }
        .thumbnail {
            img {
                border-radius: var(--radius);
            }
        }
    }
}

@-webkit-keyframes radialanimation {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(-50%, -50%, 0) scale(0.1);
        transform: translate3d(-50%, -50%, 0) scale(0.1);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
        transform: translate3d(-50%, -50%, 0) scale(1);
    }
}

@keyframes radialanimation {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(-50%, -50%, 0) scale(0.1);
        transform: translate3d(-50%, -50%, 0) scale(0.1);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
        transform: translate3d(-50%, -50%, 0) scale(1);
    }
}

/*----------------------------
    Timeline Style Two  
----------------------------*/
.timeline-style-two {
    position: relative;
    padding: 20px 50px 50px 50px;
    border-radius: 16px;
}

.rn-timeline-single {
    margin-top: 30px;

    .rn-timeline {
        text-align: center;

        .progress-line {
            position: relative;
            border-radius: 0px;
            height: 6px;
            box-shadow: none;
            margin: 20px 0;
            width: 100%;

            .line-inner {
                flex-direction: column;
                justify-content: center;
                overflow: hidden;
                text-align: center;
                white-space: nowrap;
                transition: width 0.6s ease;
                background-image: radial-gradient(
                    circle farthest-corner at 0%,
                    #e33fa1 25%,
                    #fb5343 45%,
                    #059dff 65%,
                    #36c6f0 85%
                );
                background-attachment: fixed;
                background-repeat: repeat-y;
                width: 100%;
                display: flex;
                height: 100%;
                @media #{'only screen and (min-width: 768px) and (max-width: 991px)'} {
                    display: none;
                }
            }
        }

        .progress-dot {
            position: relative;
            height: 30px;
            display: block;
            width: 100%;
            margin-top: -39px;
            margin-left: -15px;

            .dot-level {
                transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
                background: linear-gradient(to right, #059dff, #fb5343, #059dff);
                background-attachment: fixed;
                background-repeat: repeat-y;
                position: absolute;
                width: 28px;
                height: 28px;
                display: block;
                display: inline-block;
                text-align: center;
                border-radius: 50%;

                .dot-inner {
                    width: 12px;
                    height: 12px;
                    border-radius: 50px;
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    background-color: rgba(0, 0, 0, 0.3);
                }
            }
        }
    }

    &:first-child {
        .rn-timeline {
            .progress-line {
                width: 50%;
                left: 50%;
            }
        }
    }
    &:last-child {
        .rn-timeline {
            .progress-line {
                width: 50%;
            }
        }
    }
    .title {
        margin-bottom: 33px;
        font-size: 20px;
    }
    .description {
        padding: 0 10%;
        margin-top: 15px;
        margin-bottom: 0;
        padding: 0 10px;
        @media #{'only screen and (min-width: 768px) and (max-width: 991px)'} {
            padding: 0 10px;
        }
        @media #{'only screen and (max-width: 767px)'} {
            padding: 0 10px;
        }
    }

    &.no-gradient {
        .rn-timeline {
            .progress-line {
                .line-inner {
                    background-image: inherit;
                    background: #059dff;
                }
            }

            .progress-dot {
                .dot-level {
                    background-image: inherit;
                    background: #059dff;
                }
            }
        }
    }

    &.dark-line {
        .rn-timeline {
            .progress-line {
                .line-inner {
                    background-image: inherit;
                    background: #1e1e1e;
                }
            }

            .progress-dot {
                .dot-level {
                    background-image: inherit;
                    background: #1e1e1e;

                    .dot-inner {
                        background-color: #059dff;
                    }
                }
            }
        }
    }
}
