.code-block {
    position: relative;
    border: $border-width solid $border-color !important;
    padding: $kbd-padding-y $kbd-padding-x;
    background: $white;
    border-radius: $border-radius-sm;
    margin-bottom: 1rem;

    h6 {
        margin-top: 0px;
    }

    .prettyprint {
        background: $gray-100;
        border: none;
        padding: $kbd-padding-y $kbd-padding-x;
        margin-bottom: 0px;
    }

    .btn-copy-clipboard {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.doc-carousel {
    .carousel-item {
        // height: 300px;
    }

    .carousel-item img {
        height: 400px;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
    }
}

/* New CSS for carousel item layout */
.carousel-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media (min-width: 768px) {
    .carousel-item-container {
        justify-content: space-around;
    }
}

@media (min-width: 1024px) {
    .carousel-item-container {
        justify-content: center;
    }
    .carousel-item-container > .col-lg-4 {
        margin: 0 20px;
    }
}
